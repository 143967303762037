import { ApolloClient, ApolloError, InMemoryCache } from '@apollo/client';
import { GetServerSideProps } from 'next';

import { MilleFeuille, MilleFeuilleMain } from '@/layouts/MilleFeuille';
import { FooterRender, PageRender } from '@/modules/website-builder';
import { PAGE_AND_TENANT } from '@/queries';
import {
  PageAndTenant,
  PageAndTenantVariables,
} from '@/queries/graphql-types/PageAndTenant';
import { TradewingServerErrorCode } from '@/utilities/tradewing-server-error-code';

type SlugPageProps = {
  content: React.ComponentProps<typeof PageRender>['content'];
  tenant: React.ComponentProps<typeof FooterRender>['tenant'];
};

export const getServerSideProps: GetServerSideProps<SlugPageProps> = async (
  context
) => {
  try {
    const slug = context.params?.slug.toString();

    if (!slug) {
      return {
        notFound: true,
      };
    }

    const client = new ApolloClient({
      ssrMode: true,
      uri: process.env.NEXT_PUBLIC_APOLLO_HTTP_URI,
      cache: new InMemoryCache(),
    });

    const query = await client.query<PageAndTenant, PageAndTenantVariables>({
      query: PAGE_AND_TENANT,
      context: {
        headers: {
          tenant_domain: context.req?.headers.tenant_domain,
          authorization: `Bearer ${context.req.cookies['accounts%3AaccessToken']}`, // This is sort of dangerous but it should be fine since we dont have CORS enabled on the next.js server
        },
      },
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          id: slug,
          isSlug: true,
        },
      },
    });

    if (!query.data.page.content || !query.data.tenant.result) {
      return {
        notFound: true,
      };
    }

    return {
      props: {
        content: query.data.page.content,
        tenant: query.data.tenant.result,
      },
    };
  } catch (error) {
    if (
      error instanceof ApolloError &&
      error.graphQLErrors.some(
        (e) => e.extensions.code === TradewingServerErrorCode.SlugNotFound
      )
    ) {
      return {
        notFound: true,
      };
    }

    if (error?.networkError?.statusCode === 422) {
      return {
        notFound: true,
      };
    }

    throw error;
  }
};

const SlugPage: React.FC<SlugPageProps> = (props) => {
  return (
    <MilleFeuille>
      <MilleFeuilleMain>
        <PageRender content={props.content} />
      </MilleFeuilleMain>
      <FooterRender tenant={props.tenant} />
    </MilleFeuille>
  );
};

export default SlugPage;
